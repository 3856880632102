export default [
  {
    title: 'Inicio',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Home',
    action: 'read',
  },
  {
    title: 'Benchmarks',
    route: 'my-network-benchmarks',
    icon: 'BarChart2Icon',
    resource: 'MyNetworkBenchmarks',
    action: 'read',
  },
  {
    title: 'Instituciones',
    route: 'organizations',
    icon: 'BriefcaseIcon',
    resource: 'AllOrganizations',
    action: 'read',
  },
  {
    header: 'Admin de Sistema',
    resource: 'System',
    action: 'manage',
  },
  {
    title: 'Instituciones.',
    route: 'admin-organizations',
    icon: 'BriefcaseIcon',
    resource: 'System',
    action: 'manage',
  },
  {
    title: 'Redes',
    route: 'admin-networks',
    icon: 'Share2Icon',
    resource: 'System',
    action: 'manage',
  },
  {
    title: 'Usuarios',
    route: 'admin-users',
    icon: 'UsersIcon',
    resource: 'System',
    action: 'manage',
  },
]
