<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav
      v-if="loggedIn"
      class="nav align-items-center ml-auto"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ !user ? '??' : user.name }}
            </p>
            <span class="user-status">
              {{ !user ? '??' : userTypeText }}
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/default.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          @click="onMyInfoClick"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-1"
          />
          <span>Mi Info</span>
        </b-dropdown-item>

        <b-dropdown-item
          @click="onChangePasswordClick"
        >
          <feather-icon
            size="16"
            icon="KeyIcon"
            class="mr-1"
          />
          <span>Cambiar contraseña</span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="signOut"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-1"
          />
          <span>Cerrar sesión</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: null,
    }
  },
  computed: {
    loggedIn() {
      return !!this.user
    },
    userInfo() {
      return this.$store.state.services.userInfo
    },
    userTypeText() {
      if (!this.user) {
        return ''
      }

      if (this.user.type === 'TopManagement') {
        return 'Gerencia'
      }

      return this.user.type
    },
  },
  watch: {
    userInfo(value) {
      this.user = value
    },
  },
  methods: {
    async signOut() {
      try {
        await firebase.auth().signOut()
        this.$router.push('/login')
      } catch (err) {
        // console.log(err)
      }
    },
    onChangePasswordClick() {
      this.$router.push({ name: 'auth-change-password' })
    },
    onMyInfoClick() {
      this.$router.push({ name: 'user-profile-edit' })
    },
  },
}
</script>

<style>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 15rem;
}
</style>
